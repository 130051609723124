html{
    /*background-color: #B4DAE3;*/
    background-color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    overflow: auto !important;
}

.product-img {
    position: relative;
    display: inline-block;
}
.event-tags {
    position: absolute;
    top: 16px;
    left: 12px;
}
.event-tags .event-tag {
    padding: 3px 6px;
    font-size: 14px!important;
    font-weight: 500;
    color: #fff;
    width: auto;
    margin-bottom: 4px;
    display: inline-block;
    border-radius: 2px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

h3 {
    font-size: 22px;
}

.wl-strong {
    font-weight: 700;
    color: #333;
}

.preview-bar {
    position: fixed;
    background: #333;
    width: 100%;
    color: #fff;
    font-size: 14px;
    padding: 8px 30px;
    z-index: 9999;
}

.wl-simple-bck {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.uk-background-primary {
    /*background-color: #B4DAE3;*/
    background-color: #fff;
}
.custom-font {
    font-family: 'Roboto', sans-serif;
}

.wizard-form-btn {
    background-color: #28BB83;
    color: #ffffff;
    font-weight: 700;
    padding: 0 30px;
    border: 2px solid #25946a;
    letter-spacing: 0.4px;
}
.wizard-form-btn:hover, .wizard-form-btn.active {
    background-color: #000000;
    color: #ffffff;
    border: 2px solid #000000;
}
.wizard-form-btn:disabled, .wizard-form-btn:hover:disabled, .wizard-form-btn.active:disabled, .wizard-form-btn.uk-disabled {
    background-color: #dddddd;
    color: #ffffff;
    border: 2px solid #dddddd;
}

.form-error ul {
    list-style: none;
    padding: 0;
    margin: 8px 0 0 0;
    background: #fef4f6;
    color: #f0506e;
}

.form-error.account__passwordChecker--weak {
    padding: 10px 10px;
    margin: 8px 0 0 0;
    background: #fef4f6;
    color: #f0506e;
}

.form-error.account__passwordChecker--strong {
    padding: 10px 10px;
    margin: 8px 0 0 0;
    background: #edfbf6;
    color: #32d296;
}

.form-error ul li {
    padding: 10px 10px;
}

.uk-form-label  {
    font-size: .925rem;
}
.uk-form-label + .uk-input,
.uk-form-label + .uk-select {
    margin-top: 4px;
}
.uk-form-label.required:after {
    content:" *";
    color: red;
}

label.required::after {
    content: ' *';
    color: red;
}

.login-form .required {
    position: relative;
}

.login-form .required:after {
    content: "*";
    height: 50px;
    width: 5px;
    display: block;
    color: red;
    right: 15px;
    top: 12px;
    position: absolute;
}

.uk-input:focus, .uk-select:focus, .uk-textarea:focus {
    border-width: 1px;
}

input[type="radio"] {
    height: 15px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    position: relative;
    top: 1px;
    width: 17px;
}

.ticket .uk-label.uk-label-primary {
    position: relative;
    height: 26px;
}

.ticket .uk-label.uk-label-primary:before,
.ticket .uk-label.uk-label-primary:after {
    position: absolute;
    top: 0;
    height: 26px;
    width: 5px;
    -webkit-mask-size: cover;
    mask-size: cover;
}

.ticket .uk-label.uk-label-primary:before {
    content:' ';
    left: -4px;
    -webkit-mask: url(../../img/ticket-left.svg) no-repeat 50% 50%;
    mask: url(../../img/ticket-left.svg) no-repeat 50% 50%;
}
.ticket .uk-label.uk-label-primary:after {
    content:' ';
    right: -5px;
    -webkit-mask: url(../../img/ticket-right.svg) no-repeat 50% 50%;
    mask: url(../../img/ticket-right.svg) no-repeat 50% 50%;
}




.uk-modal-title {
    font-size: 24px;
    line-height: 1.5;
    font-weight: 900;
    font-family: 'Roboto', sans-serif;
}


.page a{
    color: #74AFC1;
}
.page .portfolio{
    overflow: hidden;
    width: 100%

}

.infos-slider-item-image {
    width: 100%;
}

.tnl-popin {
    z-index: 9999 !important;
}



.uk-navbar-sticky{
    border-bottom: 5px solid #B4DAE3;
}
.uk-navbar-container:not(.uk-navbar-transparent).uk-navbar-sticky{
    background: #ffffff;
}
.uk-navbar {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
}
.uk-navbar-nav>li.uk-active>a{
    color: #B4DAE3;
}
.uk-navbar-nav>li>a{
    color: #ffffff;
}
.uk-navbar-nav>li.active>a {
    text-decoration: underline;
}
.uk-navbar-nav>li>a:hover{
    color: #B4DAE3;
    text-decoration: underline;
}
.uk-navbar-sticky .uk-navbar-nav>li.uk-active>a{
    color: #333333;
}
.uk-navbar-sticky .uk-navbar-nav>li>a{
    color: #333333;
}
.uk-navbar-sticky .uk-navbar-nav>li>a:hover{
    color: #74AFC1;
}
.uk-navbar-nav.icon-rs>li>a{
    padding: 0 9px;
}
.uk-navbar-nav.icon-rs>li>a:hover{
    text-decoration: none;
    color: #74AFC1;
}


.uk-navbar-nav li.panier .fa-shopping-cart{
    font-size: 16px;
}


.uk-navbar-nav>li.timer-top{
    color: #ed1c24;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    min-height: 40px;
    padding: 0 15px 0 0;
    font-size: 16px;
    text-decoration: none;
}
.uk-navbar-nav>li.timer-top .timer{
    margin-left: 7px;
    font-weight: bold;
    font-size: 12px;
}


.syotimer-cell__unit {
    display: none;
}
.syotimer-cell {
    display: inline-block;
}
.syotimer-cell:first-child {
    position: relative;
    padding-right: 5px;
    margin-right: 2px;
}
.syotimer-cell:first-child::after {
    content: ":";
    position: absolute;
    right: 0;
    top: 0;
}

.uk-badge {
    background: #28bc83;
    min-width: 15px;
    height: 15px;
    font-size: 10px;
    font-weight: bold;
    position: relative;
    z-index: 2;
    margin-top: -5px;
    right: 4px;
}
.uk-navbar-toggle span {
    font-size: 12px;
}





.intro h1{
    font-weight: 900;
    font-size: 28px;
}
.intro h4{
    font-weight: 300;
    color: #333333
}


.main-content {
    border-top: 10px solid #74AFC1;
    position: relative;
}
.main-content h1 {
    font-weight: 900;
}

.main-content .uk-button-text {
    margin-top: 5px;
    color: #74AFC1;
}
.uk-button-text::before {
    border-bottom: 1px solid #74AFC1;
}

.button-promo, .button-promo:hover {
    color: #d9d8d8 !important;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    padding: 10px;
}


/*_________*/

.container-filters {
    margin-left: 0px !important;
}


.filter .uk-navbar-nav>li>a {
    color: #999;
}

.filter .tri {
    font-size: 14px;
}
.filter .tri select{
    margin-left: 10px;
}

.filter .uk-subnav {
    margin-left: 0px;
}
.filter .uk-subnav>* {
    flex: none;
    padding-left: 0px;
}
.filter .uk-subnav-pill>.uk-active>a {
    background-color: transparent;
    color: #74AFC1;
}
.filter .uk-subnav-pill>*>:first-child {
    padding: 0 0 0 10px;
    font-size: 20px;
}


.filter .uk-navbar-nav>li {
    padding: 0 0 0 10px;
    font-size: 20px;
    margin: 0
}
.filter .uk-navbar-nav>li>a {
    font-size: 20px;
    padding: 0;
}
.filtre-sup {
    margin: 0 10px;
}
.filtre-sup:first-child {
    margin-left: 0;
}
#toggle-animation-filtres-sup.uk-navbar {
    font-weight: 400;
    font-size: 12px;
}



.filter .active{
    color: #DF981D;
}
.filter .active a, .filter .active a:hover{
    color: #DF981D;
}
.filter .uk-input:focus, .filter .uk-select:focus, .filter .uk-textarea:focus {
    outline: 0;
    background-color: #fff;
    color: #666;
    border-color: #DF981D;
}

.filter .uk-navbar-nav>li>a, .filter .uk-navbar-toggle{
    min-height: 40px;
    font-size: 18px;
}

.uk-navbar-item, .uk-navbar-nav>li>a, .uk-navbar-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    min-height: 80px;
    padding: 0 15px;
    font-size: .875rem;
}

/*_________*/

.uk-subnav-pill--faq a {
    padding: 10px 15px !important;
    font-size: 16px !important;
}

.uk-subnav-pill--faq a:hover {
    background: none;
}

.uk-subnav-pill--faq > li.uk-active:hover a {
    color: #fff !important;
}

/*_________*/

.list-event:first-child {
    margin-top: 8px;
}
.list-event .infos-event span{
    font-size: 14px;
}
.list-event span.main-name{
    display: block;
    font-size: 18px;
    font-weight: 900;
    text-transform: uppercase;
    color: #000000;
}
.list-event span.second-name{
    display: block;
    font-weight: 700;
    text-transform: uppercase;
    color: #000000;
}
.list-event span.date{
    display: block;
    font-weight: 300;
    color: #000000;
}
.list-event span.ville{
    display: block;
    font-size: 18px;
    font-weight: 900;
    text-transform: uppercase;
    color: #000000
}
.list-event .lieu{
    font-weight: 300;
    text-transform: uppercase;
    color: #000000
}
.list-event .ticket{
    text-align: right;
}
.list-event .fa-ticket-alt{
    color: #74AFC1;
}
.uk-table-hover.list-event tbody tr:not(.mobile-reserve):hover, .uk-table-hover.list-event>tr:not(.mobile-reserve):hover {
    background: #f8f8f8;
    cursor: pointer;
}

.uk-table-hover.list-event tbody tr.mobile-reserve:hover, .uk-table-hover.list-event>tr.mobile-reserve:hover {
    background: none !important;
}


.list-event .date-col{
    font-size: 16px;
    line-height: 16px;
    font-weight: 300;
    text-transform: uppercase;
    color: #000000;
    text-align: right;
}
.list-event .infos-event-col span{
    display: inline-block;
    margin-right: 10px;
}
.list-event .infos-event-col span.ville{
    font-size: 20px;
    line-height: 20px;
}
.list-event .infos-event-col span.lieu{
    font-size: 13px;
}
.list-event .uk-label{
    padding: 5px 11px;
    font-size: .7rem;
    border-radius: 1px;
}
.list-event .uk-label-danger{
    background-color: #FC0600;
}

.list-event a {
    color: initial;
    text-decoration: none;
}

.list-selection-title {
    display: block;
    font-size: 22px;
    font-weight: 900;
    text-transform: uppercase;
    color: #000000;
}

/*_________*/

.grid-event .uk-card:hover{
    cursor: pointer;
}
.grid-event .uk-card-body {
    padding: 0px;
}
.grid-event span{
    font-size: 13px;
}
.grid-event span.date{
    display: block;
    font-weight: 300;
    font-size: 13px;
    padding: 10px 10px 0 10px;
}
.grid-event .infos-event1 {
    font-size: 14px;
    border-bottom: 1px solid #e5e5e5;
    padding: 10px;
}
.grid-event span.main-name{
    display: block;
    font-size: 16px;
    font-weight: 900;
    text-transform: uppercase;
    color: #000000;
}
.grid-event span.second-name{
    display: block;
    font-weight: 700;
    text-transform: uppercase;
    color: #000000
}
.grid-event .infos-event2 {
    font-size: 13px;
    padding: 10px;
}
.grid-event span.ville{
    display: block;
    font-size: 14px;
    font-weight: 900;
    text-transform: uppercase;
    color: #000000
}
.grid-event .lieu{
    font-weight: 300;
    text-transform: uppercase;
    color: #000000
}


.grid-event .ticket{
    text-align: right;
}
.grid-event .fa-ticket-alt{
    color: #DF981D;
}

.grid-event-item:hover {
    color: initial;
    text-decoration: none;
}

/*_________*/




.event .infos{
    max-height: calc(100vh);
    overflow: auto;
    font-family: 'Roboto', sans-serif;
}
.event h1{
    font-weight: 900;
    text-transform: uppercase;
    color: #000000;
    font-size: 36px;
}
.event h2{
    font-size: 22px;
    text-transform: uppercase;
    color: #111;
    font-weight: 500;
}

.event .far, .event .fas{
    margin-right: 7px;
    color: #74AFC1;
    font-size: 14px;
    margin-left: 7px;
}
.event:first-child .far, .event:first-child .fas {
    margin-left: 0;
}

.event .date{
    font-weight: 700;
    font-size: 22px;
    color: #111;
    margin-top: 15px;
}
.event span{
    display: inline-block;
}
.event .heure{
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    margin-right: 7px;
    color: #333;
}
.event .ville, .event .list-event span.ville{
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    margin-right: 7px;
    color: #333;
}
.event .lieu{
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    margin-right: 7px;
    color: #333;
}
.event .date-details{
    margin-top: 3px;
}

.event .choix{

}
.event .categorie {

}
.event .uk-accordion-title.categorie {
    padding: 10px;
    font-size: 16px;
    font-weight: 900;
    text-transform: uppercase;
    background-color: #f8f8f8;
}


.event .categorie .a-partir{
    font-size: 12px;
    font-weight: 300;
    color: #333;
    float: right;
    text-transform: none;
    margin-right: 10px;
}


.event .uk-table td {
    padding: 8px;
    font-size: 14px;
    vertical-align: top;
}
.event .uk-table td:first-child {
    padding-left: 16px;
}

.event td.number-place {
    font-weight: 900;
    text-align: right;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 10px;
}

.event .number-place i{
    font-size: 16px;
    margin: 0 5px
}
.event .number-place i:hover{
    color: #333333
}

.event .type{
    font-weight: 900;
}

.event .recap-choix{
    font-size: 14px;
    margin-bottom: 5px;
}

.wl-booked-btn {
    background-color: #74AFC1;
    color: #ffffff !important;
    font-weight: 700;
    font-size: 16px;
    border: none;
}
.wl-booked-btn:hover {
    background-color: #333333 !important;
    color: #fff !important;
    border: none;
}

.event .choix .uk-accordion>:nth-child(n+2) {
    margin-top: 0;
}

.event .choixsurplan{
    font-weight: 700;
    font-size: 16px;
    color: #333;
    border: 1px solid #74AFC1;
}
.event .choixsurplan:hover{
    color: #333;
    border: 1px solid #333;
}

.event .uk-accordion-title{
    font-weight: 700;
    color: #333;
    font-size: 16px;
}

.event .uk-accordion-title:hover{
    color: #74AFC1;
}
.event .uk-accordion-title:focus{
    color: #333;
}

.event .uk-accordion-title i{

}

.event .uk-accordion-title i.fa-plus{
    float: right;
    display: block;
}
.event .uk-open .uk-accordion-title i.fa-plus{
    display: none;
}
.event .uk-open .uk-accordion-title i.fa-minus{
    float: right;
    display: block;
}
.event .uk-accordion-title i.fa-minus{
    display: none;
}

.event .uk-accordion-title::before{
    display: none;
}

.event .uk-table-hover.list-event tbody tr:hover, .event .uk-table-hover.list-event>tr:hover {
    background: transparent;
    cursor:default;
}
.event .availability-total-plural {
    text-transform: uppercase;
}

#availabilityMap.uk-modal-full {
    background: rgba(0,0,0,.6) !important;
}

.map-btn-addCart-js.uk-button-primary:not([disabled])  {
    background-color: #000;
}

._panierPlandyn .uk-container {
    padding-left: 0;
}


input.quantite {
    font-family: 'Roboto', sans-serif;
    border: 0 none;
    width: 20px;
    text-align: center;
    outline: none;
    font-weight: 700;
    font-size: 18px;
    color: #666;
    padding: 0;
    background-color: inherit;
}

.ma-commande .uk-progress::-webkit-progress-value {
    background-color: #28bc83;
    transition: width .6s ease;
}
.ma-commande .step{
    color:#999999;
    font-size: 14px
}
.ma-commande .step .active{
    color:#333333;
    font-weight: 700;
}
.ma-commande .event-cart span, .modal-detail .event-cart span, .mon-compte .event-cart span{
    display: block;
    font-size: 12px;
    text-transform: uppercase
}
.ma-commande .event-cart span.nom, .modal-detail .event-cart span.nom, .mon-compte .event-cart span.nom{
    font-size: 14px;
    font-weight: 700;
}
.ma-commande .event-cart span.radio{
    height: 1px!important;
    width: 1px!important;
    visibility: hidden;
}
.ma-commande .event-cart span.date, .mon-compte .event-cart span.date{
    margin-bottom: 8px;
    text-transform: capitalize;
}
.ma-commande .event-cart span.total, .mon-compte .event-cart span.total{
    font-size: 14px;
    font-weight: 700;
}
.ma-commande td.icon, .mon-compte td.icon{
    text-align: right
}
.ma-commande td.icon i, .mon-compte td.icon i{
    font-size: 18px;
    margin-right: 10px;
}
.ma-commande a .fa-edit, .mon-compte a .fa-edit{
    color: #32d296;
}
.ma-commande a .fa-search-plus, .mon-compte a .fa-search-plus{
    color: #1e87f0;
}
.ma-commande a .fa-trash, .mon-compte a .fa-trash{
    color: #f0506e;
}
.ma-commande a:hover i, .mon-compte a:hover i{
    color: #333;
}
.ma-commande .fa-info-circle, .mon-compte .fa-info-circle{
    color: #74AFC1;
    font-size: 18px;
    margin-left: 10px;
}
.ma-commande .fa-info-circle.info-lieu, .mon-compte .fa-info-circle.info-lieu{
    color: #999999;
    font-size: 13px;
    margin-left: 5px;
}
.ma-commande .icon-livraison, .mon-compte .icon-livraison{
    font-size: 24px;
}
.ma-commande .montant-total, .mon-compte .montant-total{
    display: block;
    font-weight: 700;
    font-size: 18px;
    color: #333;
}
.ma-commande .secure{
    color:#999999;
    font-size: 12px;
}

.ma-commande .uk-button-default.valider, #modal-inscription .valider, #modal-motdepasse .valider, .mon-compte .valider, #modal-address .valider{
    background-color: #74AFC1;
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    padding: 10px;
    border: 1px solid #74AFC1;
}
.ma-commande .uk-button-default.valider:hover, #modal-inscription .valider:hover, #modal-motdepasse .valider:hover, .mon-compte .valider:hover, #modal-address .valider:hover {
    background-color: #333333;
    border: 1px solid #333333;
}
.ma-commande .continuer{
    text-align: right;
    padding: 0 12px;
}
.ma-commande .continuer a{
    color: #74AFC1;
    text-decoration: underline;
}
.ma-commande .continuer a:hover{
    color: #333;
}


.ma-commande h3{
    font-weight: 700
}

.ma-commande .save{
    color: #999999;
    font-size: 13px;
}


.ma-commande .mdp a{
    color: #74AFC1;
    text-decoration: underline;
    font-size: 13px;
}
.ma-commande .mdp a:hover{
    color: #333;
}

.ma-commande .uk-accordion{
    border: 1px solid #74AFC1;
    padding: 20px;
}
.ma-commande .uk-accordion-title {
    font-weight: 700;
}
.ma-commande .uk-accordion-title i.fa-shopping-cart{
    color: #74AFC1;
    margin-right: 10px;
}

.ma-commande .uk-accordion-title i.fa-plus{
    float: right;
    display: block;
    margin-top:5px;
    color: #74AFC1;
}
.ma-commande .uk-open .uk-accordion-title i.fa-plus{
    display: none;
}
.ma-commande .uk-open .uk-accordion-title i.fa-minus{
    float: right;
    display: block;
    margin-top:5px;
    color: #74AFC1;
}
.ma-commande .uk-accordion-title i.fa-minus{
    display: none;
}
.ma-commande .uk-accordion-title::before{
    display: none;
}

.ma-commande .uk-button-default.valider-paiement, .ma-commande .uk-button-default.eticket, .mon-compte .uk-button-default.eticket{
    background-color: #28bc83;
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    padding: 10px;
    border: 1px solid #28bc83;
}
.ma-commande .uk-button-default.valider-paiement:hover, .ma-commande .uk-button-default.eticket:hover, .mon-compte .uk-button-default.eticket:hover {
    background-color: #333333;
    border: 1px solid #333333;
}
.ma-commande .uk-button-default.eticket i{
    margin-right: 10px;
    color: #ffffff;
}
.ma-commande .cb-card img{
    height: 30px;
}
.ma-commande .cgv a{
    color: #74AFC1
}

.ma-commande  .valid .fa-check-circle{
    font-size: 120px;
    color: #28bc83;
}

.ma-commande .bt-confirm {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #333;
    border: 1px solid #999999;
    padding: 15px 0;
}
.ma-commande .bt-confirm:hover {
    border: 1px solid #333333;
}



.modal-detail h3, #modal-livraison h3, #modal-inscription h3, #modal-motdepasse h3, #modal-address h3{
    font-weight: 700;
    font-size: 1.5rem;
}
.ma-commande .champs{
    color: #999999;
    font-size: 13px;
}
.ma-commande .uk-heading-bullet{
    font-size: 14px;
    font-weight: 700
}

.ma-commande  .icon-alert .fas,
.ma-commande  .icon-alert .far {
    font-size: 120px;
}
.ma-commande .icon-alert.valid {
    color: #28bc83;
}
.ma-commande .icon-alert.warning {
    color: #faa05a;
}
.ma-commande .icon-alert.danger{
    color: #f0506e;
}

.danger-message {
    color: #ed1c24;
}

.success-message {
    color: #28bc83;
}


.holders .event-form-step {
    display: none;
}
.holders .event-form-step.active {
    display: block;
}


.mon-compte .bloc-menu{
    border-right: 2px solid #f0f0f0;
}
.mon-compte .bloc-menu h1{
    font-weight: 900;
    font-size: 28px;
}
.mon-compte .bloc-menu h1 i{
    font-weight: 900;
    font-size: 28px;
    color: #74AFC1;
}
.mon-compte .uk-nav-default>li{
    font-size: 15px
}

.mon-compte .uk-nav-divider{
    margin: 10px 0;
}
.mon-compte .uk-nav-default>li.active{
    font-weight: 700;
    color: #333333
}
.mon-compte .uk-nav-default>li.active>a{
    font-weight: 700;
    color: #333333
}
.mon-compte .compte-content h1{
    font-weight: 900;
    font-size: 28px;
}


.mon-compte .liste-commande{
    font-weight: 400;
    font-size: 14px;
}
.mon-compte .liste-commande a{
    color: #74AFC1;
}
.mon-compte .liste-commande .event{
    font-weight: 700;
    text-transform: uppercase
}
.mon-compte .commande, .mon-compte .pool-title {
    font-weight: 700;
    color: #333;
    text-transform:uppercase
}
.mon-compte td.icon{
    text-align: right;
    width: 110px;
}
.mon-compte i{
    margin-right: 10px
}
.mon-compte a:hover i{
    color: #333;
}
.mon-compte .fa-circle{
    font-size: 14px;
}
.mon-compte .fa-circle.envoye{
    color: #32d296;
}
.mon-compte .fa-circle.en-cours{
    color: #faa05a;
}

.mon-compte .historique {
    font-weight: 700;
    font-size: 16px;
    color: #333;
    border: 1px solid #74AFC1;
}
.mon-compte .historique:hover {
    border: 1px solid #333333;
}


.mon-compte .uk-heading-bullet{
    font-size: 14px;
    font-weight: 700
}

.mon-compte .info-adresses span{
    display: block;
    font-size: 14px;
}
.mon-compte .info-adresses span.nom{
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase
}
.mon-compte a .fa-edit {
    color: #32d296;
}
.mon-compte a .fa-trash {
    color: #f0506e;
}
.mon-compte a:hover button i {
    color: #ffffff;
}
.mon-compte .uk-button-default.facture{
    background-color: #1e87f0;
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    padding: 10px;
    border: 1px solid #1e87f0;
}
.mon-compte .uk-button-default.facture:hover{
    background-color: #333333;
    border: 1px solid #333333;
}




.menu-top-mob{
    min-height: 40px;
    background-color: #ffffff;
    border-bottom: 2px solid #f0f0f0;
}
.menu-top-mob .uk-navbar-container:not(.uk-navbar-transparent) {
    background: #ffffff;
}
.menu-top-mob .uk-navbar-item, .menu-top-mob .uk-navbar-nav>li>a, .menu-top-mob .uk-navbar-toggle{
    min-height: 40px;
    font-size: 18px;
}
.menu-top-mob .uk-navbar-nav>li>a {
    color: #999999;
}
.menu-top-mob .uk-navbar-nav>li>a:hover {
    color: #74AFC1;
}
.menu-top-mob .uk-navbar-nav>li.timer-top{
    color: #ed1c24;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    min-height: 40px;
    padding: 0 15px;
    font-size: 18px;
    text-decoration: none;
}
.menu-top-mob .uk-navbar-nav>li.timer-top .timer{
    margin-left: 7px;
    font-weight: bold;
    font-size: 12px;
}
.menu-top-mob .uk-badge {
    background: #28bc83;
    min-width: 15px;
    height: 15px;
    font-size: 10px;
    font-weight: bold;
    position: relative;
    z-index: 2;
    margin-top: -5px;
    right: 4px;
}
.menu-top-mob .uk-navbar-toggle span {
    font-size: 12px;
}


#menu-mobile .uk-offcanvas-bar {
    background: #ffffff;
}
#menu-mobile .uk-offcanvas-bar .uk-nav-default>li>a{
    color: #000000;
    font-size: 14px;
}
#menu-mobile .uk-offcanvas-bar .uk-nav-default>li.active>a{
    text-decoration: underline;
}
#menu-mobile .uk-offcanvas-bar .uk-nav-default>li>a:hover{
    color: #74AFC1;
}
#menu-mobile .uk-offcanvas-bar .uk-nav-default>li.title{
    color: #74AFC1;
    font-weight: 700;
    margin-top: 10px;
}
#menu-mobile .uk-offcanvas-bar .uk-nav-default .uk-nav-divider{
    border-top-color: #74AFC1;
}
#menu-mobile i{
    margin-right: 5px;
    color: #999999
}
#menu-mobile .uk-navbar-nav>li>a{
    padding: 10px 5px 0 0
}
#menu-mobile .uk-navbar-nav>li>a i{
    color: #74AFC1;
    font-size: 20px;
}
#menu-mobile .uk-navbar-nav>li>a i:hover{
    color: #000000;
}



.footer{
    font-weight: 300;
    color: #ffffff;
}
.footer a{
    color: #ffffff;
}



.uk-search-default {
    width: 100%;
}
.uk-modal-close-full {
    right: 20px;
}


.list-event.uk-table td {
    padding: 0;
}

.list-event.uk-table td a {
    padding: 16px 12px;
    display: block;
}

.event-btn-search {
    margin-left: 20px;
    width: 40px;
    height: 40px;
}


.modal .close {
    color: white;
}

.modal-backdrop.show {
    opacity: 0.5;
}

.modal-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    background: #000000;
    color: #ffffff;
}
.modal-header h5 {
    color: #ffffff;
}
.modal-footer {
    background: #f8f8fa;
}

tr.mobile-reserve {
    border-top: 0 none!important;
}
.list-event.uk-table tr.mobile-reserve td a {
    padding-top: 0;
}

.uk-pagination .uk-active a {
    font-weight: bold !important;
}

.uk-pagination a {
    font-size: 18px !important;
}



/* PRODUCTS */

.event h1.cross-selling-product-title {
    font-size: 28px;
    text-transform: uppercase;
    font-weight: 900;
    color: #000 !important;
    margin-bottom: 30px;
}

.cross-selling-btn {
    background-color: #f8f8f8 !important;
    color: #000 !important;
    text-transform: inherit !important;
    border: none !important;
}

.product-category-label {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 900;
    color: #000 !important;
}

.table-product-img {
    padding: 20px !important;
}

.cross-selling-product-description, .cross-selling-product-description:hover {
    color: #666 !important;
}

.uk-accordion-title.cross-accordion-title {
    color: #000 !important;
    font-weight: 600;
}

.uk-accordion-title.cross-accordion-title:before {
    content: "";
    display: block;
    width: 15px;
    height: 3px;
    margin-left: 10px;
    float: right;
    background-image: none !important;
}

.expiration h1 {
    text-transform: uppercase;
    font-size: 36px;
    font-weight: 900;
}
.expiration > p {
    font-size: 16px;
    line-height: 1.7;
}

.cross-selling-products-table td {
    vertical-align: middle!important;
}

.cross-selling-products-table thead tr {
    background-color: #f8f8f8;
    color: #000;
}

.cross-selling-products-table thead tr {
    background-color: #f8f8f8;
    color: #000;
}

.cross-selling-products-table img {
    width: 80px;
}

.number-place-vertical {
    display: flex;
    align-items: center;
}



.uk-subnav-pill.uk-subnav-pill--faq {
    border-bottom: 4px solid #000;
    max-width: 800px;
    margin: auto;
}

.faq-card {
    margin-top: 15px;
}

.faq-card a {
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
    padding: 20px;
}

.faq-card a {
    border-radius: 5px;
}

.faq-card.uk-active a {
    box-shadow: 2px 5px 42px 5px rgb(0 0 0 / 15%);
}

.faq-card img {
    max-width: 80px;
    max-height: 80px;
    margin: 0 auto 10px;
    display: block;
}

.uk-subnav-pill.uk-subnav-pill--faq a {
    padding: 10px 15px;
}

.uk-subnav-pill.uk-subnav-pill--faq li:hover a {
    background: none;
}

.insurance-mb {
    background-color:#f8f8f8;
    margin-bottom: 30px;
}

.insurance-mb .insurance-body {
    padding: 30px 30px 20px;
}

.insurance-mb .insurance-quantity-cell {
    padding-right: 30px;
    text-align: right;
}

.order-total-mb {
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 15px;
    padding-bottom: 15px;
}

.cart-total-mb li {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.insurance-error {
    display: none;
    margin-top: 20px;
    margin-bottom: 0;
}


/*** POOL ***/

#poolCustomersTable_filter {
    margin-bottom: 20px;
}

#poolCustomersTable_filter input {
    height: 27px;
}

.mon-compte .uk-switcher h3 {
    display: none;
}

.mon-compte .uk-switcher .uk-accordion-title {
    font-size: 16px;
    font-weight: 600;
}

@media screen and (min-width: 1450px) {
    .insurance-body-cell {
        max-width: 350px;
    }
}

@media screen and (max-width: 991px) {
    .insurance-body-cell {
        display: block;
        width: 100%;
    }
    .insurance-quantity-cell {
        display: block;
        width: 100%;
        padding: 0 30px 20px;
    }

    .insurance-form {
        text-align: left;
    }


    .insurance-quantity {
        text-align: left;
    }
}

@media screen and (max-width: 960px){


    .main-content h1 {
        font-size: 30px;
    }


    #offcanvas-flip.uk-offcanvas, #offcanvas-flip-event.uk-offcanvas {
        right: 0;
        left: auto;
    }
    #offcanvas-flip.uk-offcanvas-overlay, #offcanvas-flip-event.uk-offcanvas-overlay {
        width: calc(100%);
        touch-action: none;
    }
    #offcanvas-flip .uk-offcanvas-bar, #offcanvas-flip-event .uk-offcanvas-bar {
        background: none;
        color: #666666;
        width: 100%;
    }
    .img-thumb{
        padding-right: 20px;
    }
    .infos-head.uk-grid>.uk-grid-margin {
        margin-top: 5px;
    }

    .main-content {
        margin-top: 0 !important;
    }

    .uk-table td {
        padding: 10px;
    }
    .filter .tri select {
        margin-left: 0;
    }
    .step.uk-grid>* {
        padding-left: 0;
    }

    .ma-commande .event-cart span.total, .mon-compte .event-cart span.total {
        font-size: 12px;
    }

    /*.ma-commande td.icon, .mon-compte td.icon {
        display: flex;
    }*/
    .header-mobile-image {
        height: 500px;
    }
    .wl-page-section {
        min-height: auto !important;
    }
    .uk-subnav.uk-subnav-pill--faq {
        border-bottom: none !important;
    }

    .uk-modal-full {
        width: 100vw;
    }
    ._panierPlandyn .uk-container {
        padding-left: 40px;
    }

    .event .choixsurplan {
        margin-right: 0 !important;
    }

}

@media screen and (max-width: 420px){
    html{
        line-height: 1.2;
    }
    .filtre-sup .uk-button {
        padding: 0 10px;
    }
    .list-event span {
        font-size: 14px !important;
        line-height: 18px;
    }
    .list-event span.lieu {
        font-size: 13px;
    }
    .ma-commande .uk-accordion-title {
        font-size: 16px;
    }
    .uk-table.liste-commande td {
        font-size: 12px;
        line-height: 13px;
    }
    .list-event .date-col{
        font-size: 13px;
        text-align: center;
    }
    .list-event .infos-event-col span.ville{
        font-size: 18px;
        line-height: 18px;
    }

    .infos-event a span {
        color: black;
    }

    .list-event .uk-label-danger {
        font-size: 12px;
    }
    .event h1 {
        margin-top: 20px!important;
    }
    .uk-modal-close-full {
        right: 0;
        top: 0;
    }
    .header-mobile-image {
        height: 200px;
    }
    .event .uk-table td {
        padding: 7px;
        font-size: 12px;
    }

}
